import { Component } from '@angular/core';

@Component({
  selector: 'app-parroquia',
  templateUrl: './parroquia.component.html',
  styleUrls: ['./parroquia.component.scss']
})
export class ParroquiaComponent {

}
