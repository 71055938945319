<head>
    <meta charset="utf-8">
    <meta content="width=device-width, initial-scale=1.0" name="viewport">

    <meta content="" name="description">
    <meta content="" name="keywords">

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

    <!-- Favicons -->
    <link href="/assets/img/favicon.png" rel="icon">
    <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

    <!-- Google Fonts -->
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

    <!-- Vendor CSS Files -->
    <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
    <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
    <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
    <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
    <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
    <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
    <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
    <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

    <!-- Template Main CSS File -->
    <link href="/assets/css/style.css" rel="stylesheet">

  </head>

  <body>
    <!--Main layout-->
    <main style="margin-top: 40px;">
      <div class="container pt-4"></div>
    </main>
    <!--Main layout-->

    <section id="blog" class="blog" style="margin-top: 50px;">
      <div class="section-title" data-aos="fade-up">

      <div class="fondo" data-aos="fade-up" style="color: rgb(255, 255, 255); font-size: 3vh;">  Evidencias
        <div style="justify-content:center; text-align:center;">
          <form [formGroup]="documentoForm">



          <br><label style="margin-top: 3%; margin-right: 1%;font-weight: bold; color: black;font-size: 2vh;">Seleccione los documentos / imagenes a subir:</label>
          <br><input type="file" style="margin-top: 1%;color: black; font-weight: bold;font-size: 2vh;" (change)="contarArchivos($event)" multiple>

          <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;font-weight: bold;" *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

          <div class="spinner-border text-primary" role="status"  *ngIf="subiendoDocumento"></div>

        </form>
        </div>

      </div>

    </div>

    </section>


    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

    <!-- Vendor JS Files -->
    <script src="/assets/vendor/aos/aos.js"></script>
    <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
    <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
    <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
    <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
    <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
    <script src="/assets/vendor/php-email-form/validate.js"></script>

    <!-- Template Main JS File -->
    <script src="/assets/js/main.js"></script>

  </body>

