<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Blog Single</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li>Blog Single</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog" style="margin-top: 2vh;">
      <div class="container" data-aos="fade-up" style="margin-top: 2vh;">

        <div class="row">

          <div class="col-lg-8 entries">

            <article class="entry entry-single">

              <div class="entry-img">
                <img src="/assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
              </div>

              <h2 style="text-align: center; justify-content: center;" class="entry-title">
                <a style="text-align: center;">Plaza de toros "Alberto Balderas"</a>
              </h2>

              <div class="entry-meta" style="text-align: center; justify-content: center;">
                <ul style="text-align: center; justify-content: center;">
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/physical-gallery.png"/> <a >Eventos de entretenimiento</a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/address--v1.png"/> <a ><time datetime="2020-01-01">C. Mariano Bárcenas 72, Centro, 48900</time></a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/wired/64/null/tags.png"/> <a >Diverso</a></li>
                </ul>
              </div>

              <div class="entry-content">



                         <div style="justify-content: center; text-align: center;">
                <img src="https://i.imgur.com/bIzIVPF.png" style="border-radius: 2vh;" alt="">
                 </div>
                <p style="text-align: justify; margin-top: 2vh;">
                  Aunque no es posible precisar la antigüedad del Carnaval de Autlán, una fiesta cuyos orígenes se pierden en la bruma del tiempo, sí podemos afirmar que desde sus inicios se trató de un festejo eminentemente taurino. Así lo
                   demuestra la primera noticia documental que tenemos del Carnaval, el famoso permiso concedido por el Ayuntamiento a un señor de apellido Mardueño para organizar diez corridas de toros en el año 1831, con la condición de que éstas
                   terminaran precisamente el Martes de Carnaval.
                  Desde luego que las corridas de toros son la divisa del Carnaval de Autlán, esa parte de la fiesta que, si bien ya no es la más concurrida, sí es la que le da identidad. Pero no es el único elemento del Carnaval que tiene su origen
                   en la fiesta de los toros: el recibimiento, que en nuestros días es un baile popular que se celebra por las tardes, en su origen fue una comida y baile que se ofrecía, a manera de agradecimiento, al ganadero que había brindado los
                   toros para los festejos del día; las farolas, un desfile que se celebra por la noche para anunciar las actividades del día siguiente, partía de la plaza de toros precisamente al terminar el festejo taurino de la tarde, por lo que estaba
                   regido por la duración y las circunstancias de la corrida de toros. Y, por supuesto, hay que mencionar la otra vertiente de la fiesta taurina, la del jaripeo, que en sus modalidades de toro de once y de toro nocturno constituye en la
                   actualidad una de las principales actividades del Carnaval de Autlán.
                  Dicho esto, podemos afirmar que el Carnaval de Autlán está ligado sólidamente a la tauromaquia, que es su seña identitaria y razón de ser.

                </p>

                <p style="text-align: justify;">
                  Y, para poder celebrar festejos taurinos como los del Carnaval de Autlán, se necesita una plaza de toros cómoda, segura y con capacidad suficiente para el número de aficionados que se congregan.
                  Pero la plaza de toros no es solo un inmueble utilitario, como lo sería un salón de juntas o una oficina: la historiadora Patricia Núñez Gómez la define muy bien como “el centro de la fiesta, ya que en ella se
                  realizaban los espectáculos taurinos y se tenía la oportunidad de saludar a los amigos , comentar las últimas novedades, lucir las mejores galas, escuchar música y también (quizá) enamorarse”. La plaza de toros
                  es, pues, el centro neurálgico de la fiesta, el punto desde el que irradia el ánimo festivo.

                </p>



<!--
                <blockquote>
                  <p>
                    Et vero doloremque tempore voluptatem ratione vel aut. Deleniti sunt animi aut. Aut eos aliquam doloribus minus autem quos.
                  </p>
                </blockquote> -->

                <p style="text-align: justify;">
                  A lo largo de la historia conocida del Carnaval, Autlán ha tenido distintos cosos taurinos. Hasta antes de 1940 la plaza de toros se armaba,
                  con tablas, petates y sillas, en las semanas previas al inicio del Carnaval, inundando el entonces pequeño pueblo con el olor característico de estos materiales, dando origen a la
                  famosa expresión “Ya huele a petate”, que todavía es de uso común para indicar la cercanía de las fiestas. Esta placita de madera y petates era desmontada al término del Carnaval, por lo que su
                   existencia era efímera, pero su construcción y desmantelamiento eran el fruto de grandes trabajos de gestión y de obra: por ejemplo, la construcción la hacía en los años 1930 el señor José Gómez Llamas,
                   dibujando el círculo para el ruedo y colocando alrededor de éste las barreras, el callejón y los tendidos, con palcos para las reinas de cada día y sus respectivos séquitos, un espacio elevado para la banda de
                   música y un espacio cubierto en la parte superior. La construcción no implicaba mayor problema, siempre que estuvieran disponibles los materiales, lo cual no necesariamente estaba asegurado. Como ejemplo de esto
                   podemos tomar el Carnaval de 1939, el previo al del estreno de la plaza Alberto Balderas.

                </p>


                <p style="text-align: justify;">
                  El Carnaval de ese año fue organizado por un comité presidido por el señor Manuel C. Valencia, que desde su primera sesión enfrentó el problema de conseguir los materiales para la construcción de la plaza. Ahí mismo se
                  comprometieron a aportar vigas los señores Eduardo Dávila, Sergio Corona Blake (el futuro empresario de las fiestas de El Grullo), Luis Michel, Luis Villaseñor, Alfonso Pelayo, Miguel Díaz Santana, Carlos Pelayo y Jaime Llamas;
                  el doctor Luis Enrigue (abuelo del escritor Álvaro Enrique) ofreció conseguir madera que había en el puente de Armería (el del Corcovado) y el presidente del comité prometió conseguir los postes. Don Jaime Llamas propuso, además,
                  designar comisiones en los cuarteles en que estaba dividido el pueblo para conseguir entre los vecinos la madera que hiciera falta.

                </p>
                <img src="/assets/img/blog/blog-inside-post.jpg" class="img-fluid" alt="">

                <p style="text-align: justify;">
                  En total, se consiguieron para ese Carnaval 262 vigas grandes, seis cortas, 12 morillos de 6 varas, 70 postes de 3 y media varas, 25 latillas, 66 viguetas, 3 soleras y 8 cuartones de madera del puente sobre el río Armería.
                  La disposición de madera ya estaba resuelta en la tercera sesión del comité, celebrada el 14 de enero.
                  Más o menos estas mismas vicisitudes enfrentaron los organizadores del Carnaval todos los años, mientras no se construyó la plaza de toros “de material”, que ahora conocemos. La plaza de madera y petates se construyó en
                   distintos sitios a lo largo del tiempo: durante el siglo XIX se construía en el terreno conocido precisamente como plaza de toros y que hoy ocupa el Mercado Juárez, después su sitio estaría en el barrio de Los Huizilacates
                    (cerca de la actual UPN), la Alameda, la calle Corona Araiza entre Nicolás Bravo y Gómez Farías y la cuadra donde ahora está la plaza Vista del Sol.

                </p>
                <p style="text-align: justify;">
                  Un paso trascendente en la historia y desarrollo del Carnaval de Autlán fue precisamente la adquisición de un terreno definitivo para la plaza de toros, que es en el que actualmente se asienta la plaza Alberto Balderas.
                  Aunque fue comprado por la Junta de Mejoramiento Moral, Cívico y Material hacia 1933, las obras de construcción de la nueva plaza, a cargo del señor José Gómez Llamas, el mismo albañil que construía la plaza de madera cada año,
                   no concluyeron en su primera etapa sino hasta 1950.

                </p>



                <p style="text-align: justify;">
                  La primera corrida formal que se dio en esta plaza ocurrió el 4 de febrero de 1940, con un mano a mano entre las figuras del toreo Alberto Balderas y Chucho Solórzano, dos de los matadores de mayor cartel en la escena taurina de la época,
                  que lidiaron seis toros de San Isidro Mazatepec, cruza con San Mateo. El festejo se repetiría el Martes de Carnaval, 6 de febrero, con los mismos matadores ahora enfrentándose a toros de La Punta. A juzgar por las magníficas crónicas que de
                   estas corridas dejó don Ernesto Medina Lima, se trató de un acontecimiento de primer orden para los autlenses, en ese tiempo grandes aficionados a los toros, cuya expectativa de ver a dos auténticos ases del toreo no fue defraudada, saliendo
                   de la recién estrenada plaza “con los ojos húmedos de emoción, las manos rojas y la voz enronquecida”.

                </p>


                <div style="justify-content: center; text-align: center;">
                  <img src="https://i.imgur.com/yolY4Ap.png" style="border-radius: 2vh;" alt="">
                   </div>




                <p style="text-align: justify; margin-top: 2vh;">
                  De esa primera corrida hay una anécdota interesante: Según el diario El Informador del 1 de enero de 1947, en los últimos días del año 1946 el Ayuntamiento conformó el comité que se encargaría de la organización de los festejos del
                  siguiente Carnaval, quedando presidido por el señor Alfonso Pelayo. El primero de sus trabajos fue la ejecución de algunas obras materiales en la plaza de toros, para acondicionarla para los festejos que se realizarían en el mes de febrero.
                  Entre estas obras estuvo la demolición de la entrada de sombra, hecha de adobe, para sustituirla por una de ladrillo. El corresponsal de El Informador aplaudía esta mejora en la amplitud y comodidad del ingreso, pero lamentaba también la destrucción
                  del anuncio de aquella corrida del 4 de febrero de 1940, pintado “con colores a base de anilinas y agua” en una de las paredes de esta entrada y que había resistido por casi siete años sin el menor deterioro, a pesar de encontrarse a la intemperie.

                </p>



                <p style="text-align: justify;">
                  A esta nueva plaza se le impuso el nombre de Alberto Balderas, uno de los toreros que la estrenaron, poco tiempo después de estas corridas, por un motivo más bien trágico: el también llamado Torero de México falleció a
                  consecuencia de la cornada que le propinó el toro Cobijero en el Toreo de la Ciudad de México el 29 de diciembre de ese mismo 1940, vistiendo de luto a toda la afición del mundo taurino. La plaza Alberto Balderas sería inaugurada
                  formalmente, una vez que terminaron los trabajos de construcción, el 21 de febrero de 1971 por la Junta de Mejoramiento Moral, Cívico y Material, presidida por el señor Gustavo Villaseñor García.
                  Desde su estreno, grandes figuras del toreo han pisado la arena del ruedo de la plaza Alberto Balderas. Por solo mencionar a algunas, diré que en 1942 se presentó la rejoneadora peruana Conchita Cintrón, entonces de solo
                  20 años de edad; ese mismo año estuvo aquí Juan Silveti; el gran Fermín Espinoza, “Armillita”, partió plaza el Martes de Carnaval de 1954; diez años después lo haría Manuel Benítez, “el Cordobés”. En las siguientes décadas
                  serían visitantes asiduos Eloy Cavazos, Manolo Martínez y Curro Rivera, título que ostentaría ya en el nuevo siglo el rejoneador Pablo Hermoso de Mendoza. Silverio Pérez, el Faraón de Texcoco, ya estaba listo para partir plaza el
                  7 de octubre de 1951, pero una fuerte e inesperada tormenta impidió que el festejo se celebrara.


                </p>



                <p style="text-align: justify;">
                  Un acontecimiento de relevancia, si no para el mundo taurino sí para la plaza Alberto Balderas, ocurrió la tarde del 20 de febrero de 1955, cuando por primera vez se concedió aquí la alternativa a un matador de toros: el español Francisco Sánchez,
                  “Frasquito”, quien no tuvo una gran carrera. Lo que sí estuvo a punto de cambiar el futuro de la tauromaquia fue la terrible cornada que sufrió en esta plaza el entonces jovencísimo José Tomás el 18 de febrero de 1996, cuya vida se salvó gracias a los
                  oportunos primeros auxilios que aquí se le brindaron por el médico Antonio Mancilla Huacuja.
                  Aunque eminentemente taurina, la plaza Alberto Balderas ha servido también para otro tipo de reuniones y espectáculos. Por ejemplo, el 1 de octubre de 1949 se presentó en ella el comediante Mario Moreno “Cantinflas”, en un espectáculo organizado por la
                  Junta de Mejoramiento; mientras que el 2 de febrero de 1975 ofreció un concierto el cantante Vicente Fernández. En otros momentos se han presentado artistas tan disímbolos como Luis Miguel, la Orquesta Sinfónica y el Coro de la Secretaría de la Defensa
                   Nacional, Julión Álvarez; un ballet folclórico de Corrientes, Argentina, que ofreció una distinta visión del Carnaval; entre otros.
                  En este histórico ruedo también se celebraron acontecimientos como la entrega de premios a los ganadores del Premio Nacional de Pintura José Atanasio Monroy 2008, la final del Concurso Nacional de Guitarra Carlos Santana 2009, celebraciones del Día de
                  las Naciones Unidas, del Día de las Madres, entre muchos otros. Pero un momento muy especial para nuestra identidad autlense ocurrió en esta plaza al mediodía del 15 de febrero de 1998, cuando por primera vez fue izada la bandera de Autlán, en el
                  primer domingo del Carnaval de ese año. La bandera acababa de ser adoptada como símbolo de identidad municipal por acuerdo de Ayuntamiento tomado el 9 de enero anterior.


                </p>

                <p style="text-align: justify;">
                  A partir de esto, podemos concluir que la plaza de toros Alberto Balderas no es solo un punto de reunión sino un sitio de relevancia cultural para los autlenses, uno de los edificios emblemáticos junto con la Catedral y
                  la extinta torrecilla del reloj.
                  La plaza Alberto Balderas tiene una superficie total de 6,682.72 m2, correspondiendo al ruedo 660.52 m2, 2,022.27 m2 a las graderías y 4,975.72 m2 a los corrales. Tiene ingresos por las calles de Mariano de la Bárcena y
                   Álvaro Obregón para el público, y por la de Jesús González Ortega para ganado y equipos. Cuenta con una capilla dedicada al Señor del Gran Poder, cuya primera piedra fue colocada el 12 de febrero de 1987 y a donde llegó,
                   el 7 de marzo de 1988, una imagen del Señor del Gran Poder en manos del torero Franco Cadena. Esta imagen fue donada a la Peña Taurina de Autlán por la Hermandad del Señor del Gran Poder de Sevilla, España.
                  Si tuviéramos que establecer un espacio físico que represente al carácter y la idiosincrasia autlense, tendríamos que elegir a la plaza de toros Alberto Balderas.



                </p>


                <p style="text-align: justify; color: rgb(9, 48, 147);font-size: 2vh;">

                  Referencias:

                 </p>

                <p style="text-align: justify;">

                  Caja Carnaval 1902-1943. Archivo Histórico Municipal de Autlán. (s.f.).
                  Gómez Uribe, E. (1992). Documento histórico taurino. Autlán Taurino, 4-15.
                  Gutiérrez Lugo, B. A. (2004). Templos del municipio de Autlán de Navarro. Guadalajara: Amate.
                  Medina Lima, E. (2005). Crónicas de Autlán de la Grana, Jalisco (2 ed.). Guadalajara: Acento.
                  Núñez Gómez, P. (2006). El carnaval y la historia social de Autlán. Guadalajara: Universidad de Guadalajara.


                </p>

                <p style="text-align: justify;text-align: justify; color: rgb(9, 48, 147);font-size: 2vh;" >

                 Por Guillermo Tovar Vázquez

                </p>



              </div>

              <!-- <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li><a href="#">Business</a></li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li><a href="#">Creative</a></li>
                  <li><a href="#">Tips</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div> -->

            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">





              <h3 class="sidebar-title">Lugares de Intéres</h3>
              <div class="sidebar-item recent-posts">


                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/2sVV4Hq.png" alt="">
                  <h4><a href="admin/lugares-de-interes/catedral">Catedral de la Santísima Trinidad</a></h4>
                  <time datetime="2020-01-01">Calle M. Hidalgo 74, Centro, 48900 Autlán de Navarro, Jal.
                  </time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/UpMJ6td.png" alt="">
                  <h4><a href="admin/lugares-de-interes/jardin">Jardin "Miguel Hidalgo"</a></h4>
                  <time datetime="2020-01-01"> Margarito González Rubio 9, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/museo">Museo y Centro Regional de las Artes </a></h4>
                  <time datetime="2020-01-01"> C. José María Morelos 47, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/HAVjRry.png" alt="">
                  <h4><a href="admin/lugares-de-interes/parroquia">Parroquia de El Divino Salvador "El Sagrario"</a></h4>
                  <time datetime="2020-01-01">Margarito González Rubio 10, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NbxePys.png" alt="">
                  <h4><a href="admin/lugares-de-interes/monumentos-ninos-heroes">Monumento a los Niños Heroes</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/OE2jTNN.png" alt="">
                  <h4><a href="admin/lugares-de-interes/plaza-civica">Plaza Civica</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/DEKzly6.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Capilla">Cerro de la Capilla</a></h4>
                  <time datetime="2020-01-01">Noroeste de la ciudad de Autlán</time>
                </div>

              </div><!-- End sidebar recent posts-->


            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
