import { Component } from '@angular/core';

@Component({
  selector: 'app-monumento-nh',
  templateUrl: './monumento-nh.component.html',
  styleUrls: ['./monumento-nh.component.scss']
})
export class MonumentoNhComponent {

}
