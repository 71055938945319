<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->

  <section id="blog" class="blog" style="margin-top: 50px;">
    <div class="section-title" data-aos="fade-up">
      <h4>Editar Banner:</h4>
      <div class="fondo" data-aos="fade-up">
        <div style="justify-content:center; text-align:center;">
          <form [formGroup]="documentoForm">
            <br><label style="margin-top: 2%; margin-right: 1%;font-weight: bold; margin-bottom: 2%;">Imagenes del
              banner actual (vista previa)</label>
            <div class="row" style="width: 60%; height: 80px; margin-left: 20%; margin-right: 20%;">
              <div class="col-4" *ngFor="let dato of data, let i = index">
                <figure>
                  <img [src]="this.data[i].URL" height="300" width="300" class="img-fluid" alt="">
                  <figcaption><strong>Imagen {{i+1}} </strong></figcaption>
                </figure>
              </div>
            </div>
            <br><label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Elija cual de las imagenes desea
              remplazar:</label>
            <br> <select formControlName="ID">
              <option value="1">Imagen 1</option>
              <option value="2">Imagen 2</option>
              <option value="3">Imagen 3</option>
            </select>
            <br><label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Elija la nueva imagen para el
              banner:</label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>

            <br><button style="margin-top: 2%;font-weight: bold;" (click)="cargar()">Subir imagen</button>
          </form>
        </div>
      </div>
    </div>

  </section>

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
