import { Component } from '@angular/core';

@Component({
  selector: 'app-plaza-de-toros',
  templateUrl: './plaza-de-toros.component.html',
  styleUrls: ['./plaza-de-toros.component.scss']
})
export class PlazaDeTorosComponent {

}
