import { Component } from '@angular/core';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';

@Component({
  selector: 'app-convocatoria',
  templateUrl: './convocatoria.component.html',
  styleUrls: ['./convocatoria.component.scss']
})
export class ConvocatoriaComponent {

  con: any;

  constructor(private api: Fraccion1Service) { }

  ngOnInit(): void {
    this.api.obtenerA8año(0, 0, 3000).subscribe(res => {
      this.con = res.body;
      console.log(this.con);

    })
  }

}
