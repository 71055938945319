<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">
    <nav>
      <div class="container2">
        <input id="responsive-menu" type="checkbox">
        <label for="responsive-menu">Menú <span id="menu-icon"></span></label>
        <div id="overlay" style="background-color: rgb(255, 255, 255);"></div>
        <ul>
          <li *ngFor="let dato of menu"><a href="admin/tramites-y-servicios/{{dato.AREA}}">{{dato.TITULO}}</a></li>
        </ul>
      </div>
    </nav>

    <h3
      style="text-align: center; width: 100%;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; margin-top: 3vh; margin-bottom: 2vh;">
      {{this.areas[0].TITULO}}:</h3>
    <div class="container" data-aos="fade-up">
      <div class="col-lg-12 " data-aos="zoom-in" data-aos-delay="200">
        <div style="border-radius: 3vh;" class="icon-box iconbox-orange ">
          <a href="{{this.areas1[0].DOCUMENTO}}">
            <div class="icon">
              <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                <path stroke="none" stroke-width="0" fill="#f5f5f5"
                  d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426">
                </path>
              </svg>
              <i><img width="100" height="100" [src]="this.areas1[0].ICONO" /></i>
            </div>
            <h4> {{this.areas1[0].TITULO}}</h4>
          </a>
          <button *ngIf="boton === 1" style="border-radius: 1vh; width: 130px; margin-top: 15px;"
            (click)="openModalEditar(this.areas1[0].ID, this.areas1[0].AREA, this.areas1[0].SUBAREA)">Editar <i
              class="bi bi-pencil-square"></i></button>
        </div>
      </div>
    </div>
  </section><!-- End Services Section -->

  <!-- Inicio Editar -->
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleEditar}" style="margin-top: 25vh;">
    <form [formGroup]="editarForm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div style="display: flex;flex-direction: column;">
              <h2 class="modal-title">Editar</h2>
            </div>
          </div>
          <div class="modal-body">
            <div style="display: flex;justify-content: space-between; margin-top: 2%;">
              <div class="row">
                <label style="font-weight: bold;">
                  Titulo actual:
                </label>
                <input type="text" style="width: 98%; margin-left: 13px; height: 25px;" readonly
                  placeholder="{{this.areas1[0].TITULO}}">
              </div>
            </div>
            <div style="display: flex;justify-content: space-between; margin-top: 2%;">
              <div class="row">
                <label style="font-weight: bold;">
                  Escriba el nuevo titulo:
                </label>
                <input type="text" style="width: 98%; margin-left: 13px; height: 25px;" formControlName="TITULO">
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div class="row">
                <label style="font-weight: bold;">
                  Icono actual:
                </label>
                <i><img width="100" height="100" [src]="this.areas1[0].ICONO" /></i>
                <div>
                  <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Elija el nuevo icono:</label>
                  <input #File type="file" style="margin-top: 1%;" accept='image/*' (change)="changeFileMenu($event)">
                  <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
                    *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

                  <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>
                </div>
                <div>
                  <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Elija el nuevo documento:</label>
                  <input #File type="file" style="margin-top: 1%;" (change)="changeFileMenu1($event)">
                  <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
                    *ngIf="!subiendoDocumento1 && urlDocumento1 !== '' "></i>

                  <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento1"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="closePopUpEditar()">
              Cancelar
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="editar()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- Final Editar -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
