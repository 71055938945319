import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';


@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent {
  idNoticia: any;
  boton: any;
  noticias: any;
  noticia: any;
  id: any
  estatus: any
  constructor(private activerouter: ActivatedRoute, private router: Router, private api: Fraccion1Service, private general: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }


    this.general.verNoticias().subscribe(res => {
      this.noticias = res.body;
      console.log(this.noticias);
      console.log('entra');

    })



  }



}
