import { Component } from '@angular/core';

@Component({
  selector: 'app-cerrito',
  templateUrl: './cerrito.component.html',
  styleUrls: ['./cerrito.component.scss']
})
export class CerritoComponent {

}
