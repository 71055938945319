<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Blog Single</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li>Blog Single</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog" style="margin-top: 2vh;">
      <div class="container" data-aos="fade-up" style="margin-top: 2vh;">

        <div class="row">

          <div class="col-lg-8 entries">

            <article class="entry entry-single">

              <div class="entry-img">
                <img src="/assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
              </div>

              <h2 style="text-align: center; justify-content: center;" class="entry-title">
                <a style="text-align: center;">Parroquia de El Divino Salvador (El Sagrario)</a>
              </h2>

              <div class="entry-meta" style="text-align: center; justify-content: center;">
                <ul style="text-align: center; justify-content: center;">
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/physical-gallery.png"/> <a >Eventos religiosos </a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/address--v1.png"/> <a ><time datetime="2020-01-01"></time></a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/wired/64/null/tags.png"/> <a >Gratuito</a></li>
                </ul>
              </div>

              <div class="entry-content">



                         <div style="justify-content: center; text-align: center;" >
                <img src="https://i.imgur.com/HAVjRry.png" width="600" height="400" style="border-radius: 2vh;" alt="">
                 </div>
                <p style="text-align: justify; margin-top: 2vh;">
                  La parroquia del Divino Salvador está ubicada en el centro de Autlán y constituye el corazón de la fundación española de la ciudad. De estilo sobrio y sin prácticamente adornos en la fachada, fue fundada en el año 1543 como misión
                  de los frailes franciscanos que llegaron con la intención de evangelizar a la población indígena de Autlán y su región. Por cierto, en ese momento los asentamientos humanos de Autlán estaban a varios kilómetros de distancia al norte y al oeste de la misión.

                </p>

                <p style="text-align: justify;">
                  La mencionada misión incluía la actual parroquia y su curato, así como varias de las fincas que la circundan, además de una gran parte del actual jardín Hidalgo y del portal Juárez, que hacían las veces de cementerio, a juzgar por los enterramientos que
                  se han encontrado al hacer trabajos de mantenimiento en la zona. Eso querría decir que los autlenses contemporáneos caminamos y manejamos nuestros vehículos encima de nuestros antepasados.

                </p>



<!--
                <blockquote>
                  <p>
                    Et vero doloremque tempore voluptatem ratione vel aut. Deleniti sunt animi aut. Aut eos aliquam doloribus minus autem quos.
                  </p>
                </blockquote> -->

                <p style="text-align: justify;">
                  En la actualidad la parroquia conserva algunas reliquias históricas de un gran valor que desafortunadamente es desconocido por la mayoría de los autlenses, entre ellas:

                </p>


                <p style="text-align: justify;">
                  La cruz del astillero, fabricada para el puerto de Barra de Navidad cuando se preparaba la expedición a las islas Filipinas y considerada milagrosa por los colonos españoles al sobrevivir al fuego en varias ocasiones.
                  Las campanas, aún en uso, una de las cuales fue fundida en 1831, año en el que está documentado también el primer carnaval de Autlán.

                  <br>

                  El órgano, ahora destruido, único en la región.           <br>
                  El altar mayor, detrás del cual está el altar original, que incluye un nicho para la cruz del astillero y al lado del cual se encuentran enterrados los restos de un fraile franciscano.           <br>
                  El marco de la entrada principal, que contiene una clave con un sello y la fecha 1745 grabados en la piedra.           <br>
                  La escalera de acceso al campanario, hecha de piedra y protegida por el muro del mismo material.           <br>
                  Una pintura que representa a la Santísima Trinidad y que está fechada en el año 1779, con una restauración en 1873.           <br>
                  La pila bautismal, hecha de piedra.           <br>

                </p>

                <div style="justify-content: center; text-align: center;" >
                  <img src="https://i.imgur.com/qvvNJHo.png" width="600" height="400" style="border-radius: 2vh;" alt="">
                   </div>


                <p style="text-align: justify;">
                  Además de lo anterior, la parroquia completa se puede considerar una reliquia histórica al encontrarse entre los edificios más antiguos de la región. Al respecto de esta parroquia, la ESP Bertha Alicia Gutiérrez Lugo dice en su libro "Templos del municipio
                   de Autlán de Navarro":

                </p>

                <p style="text-align: justify;">
                  "Según fray Antonio Tello, en 1543 vinieron los primeros religiosos franciscanos a evangelizar la región de Autlán, pues antes habían venido solo de paso, el primero que en ese año vino fue fray Pedro de la Concepción, él comenzó la iglesia en el sitio que
                   actualmente ocupa, que a pesar de sus varias reparaciones conserva sus características de iglesia levantada en el siglo XVI, como son: su orientación con la puerta mayor al poniente, el tener una sola nave, el sistema de construcción de los muros, el
                   presbiterio cuadrangular y más angosto que el resto de la nave, etc."

                </p>

                <p style="text-align: justify;">
                  Entre los sitios curiosos de la parroquia del Divino Salvador se encuentra un cuarto en la esquina suroeste, en el segundo piso, justo detrás del órgano donde hay una entrada con el inicio de una escalera que apenas tiene un par de escalones y no lleva a
                  ninguna parte y que, según el sacristán, en ese lugar se pensaba construir una segunda torre con campanario pero nunca se terminó.

                </p>



                <p style="text-align: justify; color: rgb(9, 48, 147);font-size: 2vh;">

                  Referencias:

                 </p>

                <p style="text-align: justify;">

                  CulturAutlán


                </p>




              </div>

              <!-- <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li><a href="#">Business</a></li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li><a href="#">Creative</a></li>
                  <li><a href="#">Tips</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div> -->

            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">




              <h3 class="sidebar-title">Lugares de Intéres</h3>
              <div class="sidebar-item recent-posts">
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Plaza-de-Toros">Plaza de Toros "Alberto Balderas"</a></h4>
                  <time datetime="2020-01-01">C. Mariano Bárcenas 72, Centro</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/2sVV4Hq.png" alt="">
                  <h4><a href="admin/lugares-de-interes/catedral">Catedral de la Santísima Trinidad</a></h4>
                  <time datetime="2020-01-01">Calle M. Hidalgo 74, Centro, 48900 Autlán de Navarro, Jal.
                  </time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/UpMJ6td.png" alt="">
                  <h4><a href="admin/lugares-de-interes/jardin">Jardin "Miguel Hidalgo"</a></h4>
                  <time datetime="2020-01-01"> Margarito González Rubio 9, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/museo">Museo y Centro Regional de las Artes </a></h4>
                  <time datetime="2020-01-01"> C. José María Morelos 47, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>


                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NbxePys.png" alt="">
                  <h4><a href="admin/lugares-de-interes/monumentos-ninos-heroes">Monumento a los Niños Heroes</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/OE2jTNN.png" alt="">
                  <h4><a href="admin/lugares-de-interes/plaza-civica">Plaza Civica</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/DEKzly6.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Capilla">Cerro de la Capilla</a></h4>
                  <time datetime="2020-01-01">Noroeste de la ciudad de Autlán</time>
                </div>


              </div><!-- End sidebar recent posts-->


            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
