import { DirectorioComponent } from './../directorio/directorio.component';
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';

import { LoginComponent } from '../LOGIN/login/login.component';


import { GobiernoComponent } from '../gobierno/gobierno.component';
import { DIFComponent } from '../dif/dif.component';
import { IMujerComponent } from '../i-mujer/i-mujer.component';
import { InfoFundamentalComponent } from '../info-fundamental/info-fundamental.component';
import { SesionesComponent } from '../sesiones/sesiones.component';
import { TramitesYServiciosComponent } from '../tramites-y-servicios/tramites-y-servicios.component';
import { VerNoticiaComponent } from '../ver-noticia/ver-noticia.component';
import { ConvocatoriaComponent } from '../convocatoria/convocatoria.component';
import { IndexComponent } from '../index/index.component';
import { TeamComponent } from '../team/team.component';
import { SubirNoticiaComponent } from '../subir-noticia/subir-noticia.component';
import { SubirImgInicioComponent } from '../subir-img-inicio/subir-img-inicio.component';
import { DesarrolloUrbanoComponent } from '../tramites-y-servicios/datos/desarrollo-urbano/desarrollo-urbano.component';
import { PasaporteComponent } from '../tramites-y-servicios/datos/pasaporte/pasaporte.component';
import { SecretariaGeneralComponent } from '../tramites-y-servicios/datos/secretaria-general/secretaria-general.component';
import { SeguridadPublicaComponent } from '../tramites-y-servicios/datos/seguridad-publica/seguridad-publica.component';
import { DesarrolloEconomicoComponent } from '../tramites-y-servicios/datos/desarrollo-economico/desarrollo-economico.component';
import { GirosComponent } from '../tramites-y-servicios/datos/secretaria-general/giros/giros.component';
import { ProvisionalesComponent } from '../tramites-y-servicios/datos/secretaria-general/provisionales/provisionales.component';
import { ConstanciasComponent } from '../tramites-y-servicios/datos/secretaria-general/constancias/constancias.component';
import { SubirTextoComponent } from '../gobierno/subir-texto/subir-texto.component';
import { LogoComponent } from 'src/app/shared/components/sidenav-admin/logo/logo.component';
import { CarnavalQrComponent } from '../Rutas-Provisionales/carnaval-qr/carnaval-qr.component';
import { ActualizarComponent } from 'src/app/shared/components/footer-admin/actualizar/actualizar.component';
import { CiudadComponent } from '../ciudad/ciudad.component';
import { TurismoComponent } from '../ciudad/turismo/turismo.component';
import { LugaresDeInteresComponent } from '../ciudad/lugares-de-interes/lugares-de-interes.component';
import { PlazaDeTorosComponent } from '../ciudad/lugares-de-interes/plaza-de-toros/plaza-de-toros.component';
import { ContanctComponent } from '../contanct/contanct.component';
import { BuscarComponent } from '../buscar/buscar.component';
import { ContratosComponent } from '../contratos/contratos.component';
import { ProteccionCivilComponent } from '../tramites-y-servicios/datos/proteccion-civil/proteccion-civil.component';
import { RegistroComponent } from '../tramites-y-servicios/datos/proteccion-civil/registro/registro.component';
import { SimulacroComponent } from '../tramites-y-servicios/datos/proteccion-civil/simulacro/simulacro.component';
import { AvisosComponent } from '../avisos/avisos.component';
import { SolicitudesComponent } from '../solicitudes/solicitudes.component';
import { ListaComponent } from '../solicitudes/lista/lista.component';
import { DatosComponent } from '../tramites-y-servicios/datos/datos.component';
import { EstatusComponent } from '../solicitudes/lista/estatus/estatus.component';
import { AuthEGuard } from 'src/app/core/guard/authE.guard';
import { CerritoComponent } from '../ciudad/lugares-de-interes/cerrito/cerrito.component';
import { CatedralComponent } from '../ciudad/lugares-de-interes/catedral/catedral.component';
import { JardinComponent } from '../ciudad/lugares-de-interes/jardin/jardin.component';
import { MonumentoNhComponent } from '../ciudad/lugares-de-interes/monumento-nh/monumento-nh.component';
import { MuseoComponent } from '../ciudad/lugares-de-interes/museo/museo.component';
import { ParroquiaComponent } from '../ciudad/lugares-de-interes/parroquia/parroquia.component';
import { PlazaCivicaComponent } from '../ciudad/lugares-de-interes/plaza-civica/plaza-civica.component';
import { HaciendaComponent } from '../tramites-y-servicios/datos/hacienda/hacienda.component';
import { PrivacyComponent } from '../Noticia1/privacy.component';
import { TestimonialsComponent } from '../Noticia2/testimonials.component';
import { ConstanciaComponent } from '../tramites-y-servicios/datos/proteccion-civil/constancia/constancia.component';
import { FiltroComponent } from '../tramites-y-servicios/datos/proteccion-civil/filtro/filtro.component';
import { DocumentoComponent } from '../tramites-y-servicios/datos/proteccion-civil/documento/documento.component';
import { GenerarReporteComponent } from '../tramites-y-servicios/datos/proteccion-civil/generar-reporte/generar-reporte.component';







const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
  {

    path: 'login',
    component: LoginComponent
  },
  {
    path: 'gobierno',
    component: GobiernoComponent
  },
  {
    path: 'dif',
    component: DIFComponent
  },
  {
    path: 'instituto-de-la-mujer',
    component: IMujerComponent
  },
  {
    path: 'info-fundamental',
    component: InfoFundamentalComponent
  },
  {
    path: 'sesiones',
    component: SesionesComponent
  },
  {
    path: 'tramites-y-servicios',
    component: TramitesYServiciosComponent
  },
  {
    path: 'nominas',
    component: TramitesYServiciosComponent
  },
  {
    path: 'directorio',
    component: DirectorioComponent
  },

  {
    path: 'ver-noticia/:id',
    component: VerNoticiaComponent
  },
  {
    path: 'convocatoria-un-patrimonio-para-tu-familia',
    component: ConvocatoriaComponent
  },
  {
    path: 'indice',
    component: IndexComponent
  },
  {
    path: 'team',
    component: TeamComponent
  },
  {
    path: 'subir-noticia',
    component: SubirNoticiaComponent
  },
  {
    path: 'editar-banner',
    component: SubirImgInicioComponent
  },
  {
    path: 'tramites-y-servicios/1',
    component: DesarrolloUrbanoComponent
  },
  {
    path: 'tramites-y-servicios/2',
    component: PasaporteComponent
  },
  {
    path: 'tramites-y-servicios/3',
    component: SecretariaGeneralComponent
  },
  {
    path: 'tramites-y-servicios/4',
    component: SeguridadPublicaComponent
  },
  {
    path: 'tramites-y-servicios/5',
    component: DesarrolloEconomicoComponent
  },
  {
    path: 'tramites-y-servicios/6',
    component: ProteccionCivilComponent
  },

  {
    path: 'tramites-y-servicios/7',
    component: HaciendaComponent
  },
  {
    path: 'tramites-y-servicios/secretaria-general/giros',
    component: GirosComponent
  },
  {
    path: 'tramites-y-servicios/secretaria-general/provisionales',
    component: ProvisionalesComponent
  },
  {
    path: 'tramites-y-servicios/secretaria-general/constancias',
    component: ConstanciasComponent
  },
  {
    path: 'gobierno/subir',
    component: SubirTextoComponent
  },
  {
    path: 'logo',
    component: LogoComponent
  },
  {
    path: 'carnaval/Autlan/2023',
    component: CarnavalQrComponent
  },
  {
    path:'pie-de-pagina/actualizar',
    component: ActualizarComponent
  }
  ,
  {
    path:'ciudad',
    component: CiudadComponent
  }
  ,
  {
    path:'turismo',
    component: TurismoComponent
  }
  ,
  {
    path:'svg',
    component: LugaresDeInteresComponent
  } ,
  {
    path:'lugares-de-interes/Plaza-de-Toros',
    component: PlazaDeTorosComponent
  },
  {
    path:'lugares-de-interes',
    component: CiudadComponent
  }
  ,
  {
    path:'contac',
    component: ContanctComponent
  },
  {
    path:'buscar',
    component: BuscarComponent,
    canActivate:[AuthEGuard],
  },
  {
    path: 'contratos/:anio/:tri',
    component: ContratosComponent
  },
  {
    path: 'proteccion-civil/registro',
    component: RegistroComponent
  },
  {
    path: 'proteccion-civil/simulacro',
    component: SimulacroComponent
  },
  {
    path: 'avisos-de-privacidad',
    component: AvisosComponent
  }
  ,
  {
    path: 'solicitudes',
    component: SolicitudesComponent,
    canActivate:[AuthEGuard],
  }
  ,
  {
    path: 'lista-solicitudes',
    component: ListaComponent,
    canActivate:[AuthEGuard],
  }

  ,
  {
    path: 'listado/:id',
    component: EstatusComponent,
    canActivate:[AuthEGuard],
  },
  {
    path: 'lugares-de-interes/Capilla',
    component: CerritoComponent
  },
  {
    path: 'lugares-de-interes/jardin',
    component: JardinComponent
  }
  ,
  {
    path: 'lugares-de-interes/monumentos-ninos-heroes',
    component: MonumentoNhComponent
  }
  ,
  {
    path: 'lugares-de-interes/museo',
    component: MuseoComponent
  }
  ,
  {
    path: 'lugares-de-interes/parroquia',
    component: ParroquiaComponent
  }
  ,
  {
    path: 'lugares-de-interes/catedral',
    component: CatedralComponent
  }
  ,
  {
    path: 'lugares-de-interes/plaza-civica',
    component: PlazaCivicaComponent
  }
  ,
  {
    path: 'noticias/1',
    component: PrivacyComponent
  },

  {
    path: 'noticias/2',
    component: TestimonialsComponent
  },

  {
    path: 'proteccion-civil/constancias',
    component: ConstanciaComponent
  },

  {
    path: 'proteccion-civil/filtro',
    component:FiltroComponent
  },

  {
    path: 'proteccion-civil/imprimir-constancia',
    component:DocumentoComponent
  },
  {
    path: 'proteccion-civil/reporte',
    component:GenerarReporteComponent
  }


];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DashboardRoutingModule { }
