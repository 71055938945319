import { Component } from '@angular/core';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss']
})
export class DocumentoComponent {
razon:any
documento:any
  constructor(private api: GeneralService, private router: Router, private alertas: SweetAlertService) { }



  ngOnInit() {

this.razon=localStorage.getItem('constancia')




    this.api.filtropc(this.razon).subscribe(res => {
      this.documento = res.body;
      console.log(this.documento);



    })



  }



  crearPDF() {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [12, 8]

    });


    doc.setFontSize(30);



    var logo = new Image();
    logo.src = 'assets/img/constancia-proteccion-civil.jpg';
    doc.addImage(logo, 'JPEG', .1, .1, 12, 8);
doc.text(this.documento[0].RAZON_SOCIAL , 6, 4.2, {align:"center"});
    doc.output('dataurlnewwindow', {filename: 'Constancia - Primer Simulacro Nacional.pdf'});
doc.save('Constancia - Primer Simulacro Nacional.pdf')
this.alertas.realizado('Realizado','Su constancia ha sido generada con exito')

  }

}
