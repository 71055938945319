<!DOCTYPE html>
<html lang="en" data-aos="fade-up">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Autlan - Gobierno</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <section id="hero"
    style="height: 600px; margin-top: 150px; width: 80%; margin-left: 10%; margin-right: 10%; border-radius: 6vh;">


    <div id="heroCarousel" data-bs-interval="2000" class="carousel slide carousel-fade" data-bs-ride="carousel">

      <div class="carousel-inner" role="listbox">

        <!-- Slide 1 -->
        <div class="carousel-item " style="background-image: url({{this.bann[0].URL}}); ">
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px; " (click)="editar()">Editar
                Banner</button></div>
          </div>
        </div>

        <!-- Slide 2 -->
        <div class="carousel-item " style="background-image: url({{this.bann[2].URL}}); ">
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px; " (click)="editar()">Editar
                Banner</button></div>
          </div>
        </div>

        <!-- Slide 3 -->
        <div class="carousel-item active" style="background-image: url({{this.bann[1].URL}}); ">
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px;" (click)="editar()">Editar
                Banner</button></div>
          </div>
        </div>

      </div>
    </div>

  </section>








  <main id="main">

    <section id="testimonials" class="testimonials section-bg">

      <div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
        <div class="astrodividermask" data-aos="fade-up"></div><span
          style=" background-image: url(https://i.imgur.com/WVvdtYK.png); justify-items: start;"></span>
      </div>

      <div class="container">

        <div class="row">
          <div class="section-title" data-aos="fade-up">
            <a style="font-size: 4vh;"
              href="https://www.youtube.com/watch?v=sLpCLunrUNM&list=PLviOcDYl4pq5TQuG6qZTjcug_VXW8lkmM"
              target="_blank"> Autlán TV <strong>Noticias</strong></a>
            <h4>Videos del Ayuntamiento</h4>
            <div class="row video" style="background-color: brown;">

              <div class="col-6">
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F100064844432383%2F&tabs=timeline&width=1400&height=500&hide_cover=false&show_facepile=true&appId"
                  width="500px" height="315" style="margin-top: 2vh;   box-shadow: -5px 6px 20px 0px rgba(176, 0, 0, 0.753); justify-content: center;text-align: center; margin-left: 10vh;
                border-radius: 2vh;" scrolling="no" frameborder="0" allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div>
              <div class="col-6">
                <iframe style="margin-top: 2vh; box-shadow: -5px 6px 20px 0px rgba(176, 0, 0, 0.753);
                border-radius: 2vh;margin-left: -10vh;"
                  src="https://www.youtube.com/embed?autoplay=1&mute=1&listType=playlist&list=PLviOcDYl4pq5TQuG6qZTjcug_VXW8lkmM"
                  width="500" height="315" frameborder="0" allowfullscreen allow="autoplay;">
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="team" class="team section-bg">
      <div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
        <div class="astrodividermask" data-aos="fade-up"></div><span
          style=" background-image: url(https://i.imgur.com/WVvdtYK.png); justify-items: start;"></span>
      </div>
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Últimas <strong>Noticias</strong></h2>
          <div *ngIf="boton === 1" style="margin-bottom: 15px;">
            <button style="border-radius: 1vh; width: 180px;" (click)="subirNoticia()">Agregar Noticia <i
                class="bi bi-cloud-arrow-up"></i></button>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" *ngFor="let noticia of data">
            <div class="member" data-aos="fade-up" data-aos-delay="100" style="border-radius: 3vh;">
              <div class="member-img" style="max-height:180px; overflow: hidden;">
                <img [src]="noticia.ENLACE" height="300" width="300" class="img-fluid" alt="">

              </div>
              <div class="member-info">
                <a href="admin/ver-noticia/{{noticia.ID}}"
                  style="font-size: 2vh; text-align: justify;">{{noticia.ENCABEZADO}}</a>
                <span> {{noticia.FECHA}}</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="100" style="border-radius: 3vh;">
              <div class="member-img" style="max-height:180px; overflow: hidden;">
                <img src="https://i0.wp.com/autlan.gob.mx/wp-content/uploads/2022/08/Captura.png?w=410&ssl=1"
                  class="img-fluid" alt="">

              </div>
              <div class="member-info">
                <a href="admin/noticias/1"
                  style="font-size: 2vh;">
                  Licitación: Paneles solares del rastro municipal</a>
                <span> H. Ayuntamiento Agosto 29, 2022</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="100" style="border-radius: 3vh;">
              <div class="member-img" style="max-height:180px; overflow: hidden;">
                <img
                  src="https://i0.wp.com/autlan.gob.mx/wp-content/uploads/2022/08/Infografia-1-Conoce-los-instrumentos-que-norman-la-Contraloria-Socia.jpeg?resize=768%2C769&ssl=1"
                  class="img-fluid" alt="">
              </div>
              <div class="member-info">
                <a style="font-size: 2vh;"
                  href="admin/noticias/2">Conoce los
                  instrumentos que norman la Contraloría Social</a>
                <span> H. Ayuntamiento Agosto 24, 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main><!-- End #main -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
