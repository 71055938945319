import { Component } from '@angular/core';

@Component({
  selector: 'app-plaza-civica',
  templateUrl: './plaza-civica.component.html',
  styleUrls: ['./plaza-civica.component.scss']
})
export class PlazaCivicaComponent {

}
