import { Component } from '@angular/core';

@Component({
  selector: 'app-lugares-de-interes',
  templateUrl: './lugares-de-interes.component.html',
  styleUrls: ['./lugares-de-interes.component.scss']
})
export class LugaresDeInteresComponent {

}
