import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss']
})
export class ContratosComponent {
  documento: any;
  token: any;
  docs: any;
  anio: any;
  tri: any;
  carpeta: any;
  trimestre: any;

  constructor(private api: GeneralService, private router: Router, private activerouter: ActivatedRoute) { }

  ngOnInit() {
    this.anio = this.activerouter.snapshot.paramMap.get('anio');
    this.tri = this.activerouter.snapshot.paramMap.get('tri');
    if (this.tri === '1') {
      this.carpeta = 2005;
      this.trimestre = 'Primer Trimestre';
    } else if (this.tri === '2') {
      this.carpeta = 2006;
      this.trimestre = 'Segundo Trimestre';
    } else if (this.tri === '3') {
      this.carpeta = 2007;
      this.trimestre = 'Tercer Trimestre';
    } else if (this.tri === '4') {
      this.carpeta = 2008;
      this.trimestre = 'Cuarto Trimestre';
    }else if (this.tri > 4) {
      this.router.navigate(['admin/contratos/' + this.anio + '/4']);
      this.carpeta = 2008;
      this.trimestre = 'Cuarto Trimestre';
    }else if (this.tri < 1) {
      this.router.navigate(['admin/contratos/' + this.anio + '/1']);
      this.carpeta = 2005;
      this.trimestre = 'Primer Trimestre';
    }else if (this.tri.includes('0')) {
      this.router.navigate(['admin/contratos/' + this.anio + '/1']);
      this.carpeta = 2005;
      this.trimestre = 'Primer Trimestre';
    }
    console.log('Año:', this.anio, 'Trimestre:', this.tri, 'Carpeta:', this.carpeta);


    this.documento = '';

    this.api.obtenerA82018(6, 6, this.anio, this.carpeta).subscribe(res => {
      this.docs = res.body
      console.log(this.docs);
    })
  }


}
