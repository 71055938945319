<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgba(226, 226, 226, 0.617);margin-top: 100px;" id="services"
    class="services section-bg">
    <nav>
      <div class="container2">
        <input id="responsive-menu" type="checkbox">
        <label for="responsive-menu">Menú <span id="menu-icon"></span></label>
        <div id="overlay" style="background-color: rgb(250, 250, 250);"></div>
        <ul>
          <li *ngFor="let dato of menu"><a href="admin/tramites-y-servicios/{{dato.AREA}}">{{dato.TITULO}}</a></li>
        </ul>
      </div>
    </nav>

    <div class="container" data-aos="fade-up">
      <h3
        style="text-align: center; width: 100%;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; margin-top: 1vh;">
        {{this.areas[0].TITULO}}:</h3>
      <div style="justify-content: center; display: flex;" *ngIf="boton === 1"><button
          style="border-radius: 1vh; width: 130px; margin-top: 15px; text-align: center; margin-bottom: 50px;"
          (click)="openModalEditar(this.areas1[0].AREA, this.areas1[0].SUBAREA)">Editar <i
            class="bi bi-pencil-square"></i></button></div>
      <div class="row" style="width: 80%;margin-left: 20vh;">
        <div class="col-lg-12 col-md-6 d-flex align-items-stretch " data-aos="zoom-in" data-aos-delay="100"
          style="width: 100%; justify-content: center;margin-top: 30vh; ">
          <div style="border-radius: 3vh;" class="icon-box iconbox-teal">
            <a href="admin/tramites-y-servicios/desarrollo-urbano"></a>
            <div class="icon">
              <img style="border-radius: 4vh;" height="757" width="814" [src]="areas1[0].ICONO" />
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-6 d-flex align-items-stretch"
          style="width: 100%; justify-content: center; margin-top: 60vh;">
          <div style="border-radius: 3vh;" class="icon-box iconbox-teal">
            <a href="admin/tramites-y-servicios/desarrollo-urbano"></a>
            <div class="icon">
              <img style="border-radius: 4vh;" height="757" width="814" [src]="areas1[1].ICONO" />
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-6 d-flex align-items-stretch" style="margin-top: 35vh;"
          style="width: 100%; justify-content: center;margin-top: 60vh;">
          <div style="border-radius: 3vh;" class="icon-box iconbox-teal">
            <a href="admin/tramites-y-servicios/desarrollo-urbano"></a>
            <div class="icon">
              <img style="border-radius: 4vh;" height="757" width="814" [src]="areas1[2].ICONO" />
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-6 d-flex align-items-stretch" style="margin-top: 35vh;"
          style="width: 100%; justify-content: center;margin-top: 60vh;">
          <div style="border-radius: 3vh;" class="icon-box iconbox-teal">
            <a href="admin/tramites-y-servicios/desarrollo-urbano"></a>
            <div class="icon">
              <img style="border-radius: 4vh;" height="757" width="814" [src]="areas1[3].ICONO" />
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-6 d-flex align-items-stretch" style="margin-top: 35vh;"
          style="width: 100%; justify-content: center;margin-top: 60vh; ">
          <div style="border-radius: 3vh;" class="icon-box iconbox-teal">
            <a href="admin/tramites-y-servicios/desarrollo-urbano"></a>
            <div class="icon">
              <img style="border-radius: 4vh;" height="757" width="814" [src]="areas1[4].ICONO" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Services Section -->

  <!-- Inicio Editar -->
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleEditar}" style="margin-top: 22vh;">
    <form [formGroup]="editarForm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div style="display: flex;flex-direction: column;">
              <h2 class="modal-title">Editar</h2>
            </div>
          </div>
          <div class="modal-body">
            <div style="display: flex;justify-content: space-between;">
              <div class="row">
                <div>
                  <br><label style="margin-right: 1%;font-weight: bold;">Imagenes Actuales(vista previa)</label>
                  <div class="row" style="width: 100%; height: 250px;">
                    <div class="col-4" *ngFor="let img of areas1, let i = index">
                      <figure>
                        <img [src]="this.areas1[i].ICONO" height="100" width="100" class="img-fluid" alt="">
                        <figcaption><strong>Imagen {{i+1}} </strong></figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
                <br><label style="margin-right: 1%;font-weight: bold;">Elija cual de las imagenes desea
                  remplazar:</label>
                <br><select formControlName="ID" style="margin-left: 2%; width: 60%;">
                  <option value="46">Imagen 1</option>
                  <option value="47">Imagen 2</option>
                  <option value="48">Imagen 3</option>
                  <option value="49">Imagen 4</option>
                  <option value="50">Imagen 5</option>
                </select>
                <div>
                  <label style="margin-right: 1%;font-weight: bold;">Elija la nueva imagen:</label>
                  <input #File type="file" style="margin-top: 1%;" accept='image/*' (change)="changeFileMenu($event)">
                  <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
                    *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

                  <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="closePopUpEditar()">
              Cancelar
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="editar()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- Final Editar -->

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
