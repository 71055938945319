<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Team - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <main id="main" style="background-image: url(https://i.imgur.com/8gnvixi.jpg); ">

    <div class="container" style="margin-top: 100px;">
      <!-- ======= Our Team Section ======= -->
      <section id="team" class="team section-bg">
        <div class="scroll">

          <div class="section-title" data-aos="fade-up">
            <h2 style="margin-top: 30px;">EQUIPO LABORAL UNIDO Y EXPERIMENTADO</h2>
            <p style="margin-top: 10px;">Son parte de toda nuestra cadena de valor, son el mejor capital humano del
              mercado,<br> especializado por sectores y áreas temáticas.</p>
          </div>



          <div class="row" style="margin: 10px;">

            <div hidden class="col-lg-4 col-md-6 d-flex align-items-stretch miembros">

              <div class="member" data-aos="fade-up">

              </div>
            </div>


            <div class="col-lg-4 col-md-6 d-flex align-items-stretch miembros">

              <div class="member" data-aos="fade-up">
                <div class="member-img">
                  <img src="https://i.imgur.com/GYpFo5f.png" class="img-fluid" alt="">
                  <div class="social" style="height: 150px;">
                    <p style="justify-content: center;text-align: center; font-weight: bold;  margin: 2vh;">""</p>

                  </div>
                </div>
                <div class="member-info">
                  <h4>Gustavo Robles</h4>
                  <span>Presidente Municipal</span>
                </div>
              </div>
            </div>


            <div class="col-lg-4 col-md-6 d-flex align-items-stretch miembros" hidden>


            </div>



          </div>




          <div class="row" style="margin: 10px;">

            <div *ngFor="let dato of noticias" class="col-lg-2 col-md-6 d-flex align-items-stretch miembros">

              <div class="member" data-aos="fade-up">
                <div class="member-img">
                  <img [src]="dato.DATO" class="img-fluid" alt="">
                  <div class="social" style="height: 150px;">
                    <p
                      style="justify-content: center;text-align: center; font-weight: bold; margin: 2vh; font-size: 1.2vh;">
                      "{{dato.MENSAJE}}"</p>

                  </div>
                </div>
                <div class="member-info">
                  <h4>{{dato.NOMBRE}}</h4>
                  <span>{{dato.POCISION}}</span>


                </div>
              </div>
            </div>


          </div>

        </div>
      </section><!-- End Our Team Section -->

      <div class="menu-container" id="toggle">
        <a href="" class="menu"><i class="fa fa-bars" aria-hidden="true"></i></a>
      </div>




    </div>
    <div class="overlay" id="overlay">
      <nav class="overlay-menu">
        <ul>
          <li><a href="#"
              style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Ayuntamiento</a></li>
          <li><a href="#" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Directores -
              Secretario Particular</a></li>
          <li><a href="#" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Jefes de
              Area</a></li>
          <li><a href="#" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Agentes y
              Delegados</a></li>
        </ul>
      </nav>
    </div>





  </main><!-- End #main -->









  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
