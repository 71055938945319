import { Component, OnInit } from '@angular/core';
declare var svg:any;
@Component({
  selector: 'app-contanct',
  templateUrl: './contanct.component.html',
  styleUrls: ['./contanct.component.scss']
})
export class ContanctComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
svg()
  }



}
