<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
</head>


<!--Card fondo-->

<div class="fondo"
  style="height: 600px;color: #054291; margin-top: 25vh; text-align: center; font-size: 4vh; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; ">
  Solicitudes






  <ul class="list-group" style="width: 100%; text-align: center;" class="animate__animated animate__fadeInDown">
    <div class="div" style="width: 100%; margin-left: 10%;margin-right: 10%;text-align: center;">

      <div *ngIf="this.spinner===true"
        style="color: #EE4B90; width: 100px; height: 100px; margin-top: 10%; font-size: 30px;" class="spinner-border"
        role="status">

        <span class="visually-hidden">Loading... </span>

      </div>
    </div>




    <div id="carouselExampleIndicators" class="carousel slide">

      <div class="carousel-indicators" style="width: 70%;">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
          aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
          aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
          aria-label="Slide 3"></button>
      </div>



      <div class="row">




        <div class="col-1" style="position: static;">
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"
              style="background-color: #054291; border-radius: 10vh;width: 4vh;height: 4vh;margin-right: 13vh;"></span>
            <span class="visually-hidden">Previous</span>
          </button>
        </div>


        <div class="col-10" style="height: 542px; border-radius: 2vh;border: solid;">
          <div id="scroll">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                      style="color: #054291; font-weight: bold;">
                      Sin atender
                      <span class="badge text-bg-secondary"
                        style="color: rgb(255, 255, 255); background-color: #e91111;margin-left: 2.5vh; width: 4vh;height: 2vh; text-align: center;">{{this.conteo}}</span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <!--Elemento-->

                      <li id="lista1" class="list-group-item animate__animated animate__fadeInDown"
                        (click)="verDetalles(dato.ID)" *ngFor="let dato of data"
                        style="display:flex; flex-direction:column; justify-content:center">
                        <div class="row">

                          <!--Foto-->
                          <div id="ocultar-div" class="col-2">
                            <img style="border-radius: 50px;" src="https://i.imgur.com/8C8UuNl.png" alt="" height="120"
                              width="120">
                          </div>
                          <!--Termina Foto-->

                          <!--Datos-->

                          <div class="col-1" ></div>

                          <div class="col-4" id="reducir" style="margin-left: -2vh;" (click)="verDetalles(dato.ID)">



                            <div class="row" (click)="verDetalles(dato.ID)">
                              <div class="col-2" >
                                <h6 style="color: #dc0101;text-align: start;font-size: 1.5vh;">
                                  Nombre:
                                </h6>
                              </div>
                              <div class="col-10" style="justify-content: center;text-align: center;">
                                <h6 style="color: #054291;text-align: start;font-size: 1.5vh;">
                                  {{dato.NOMBRE}} {{dato.APELLIDOS}} </h6>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-2"><!--  -->
                                <h6 style="color: #dc0101;text-align: start;" class="d-none d-sm-none d-md-block">
                                  Domicilio:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;" class="d-none d-sm-none d-md-block">
                                  {{dato.DIRECCION}}</h6>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-2">
                                <h6 style="color: #dc0101;text-align: start;" class="d-none d-sm-none d-md-block">
                                  Localidad:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;" class="d-none d-sm-block d-md-block">
                                  {{dato.LOCALIDAD}} </h6>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-2">
                                <h6 style="color: #dc0101; text-align: start;" class="d-none d-sm-none d-md-block">
                                  Fecha:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;" class="d-none d-sm-block d-md-block">
                                  {{dato.FECHA}} </h6>
                              </div>
                            </div>


                            <div class="row">
                              <div class="col-2"><!--  -->
                                <h6 style="color: #dc0101;text-align: start;font-size: 1.5vh;">
                                  Responsable:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;margin-left: 2vh;font-size: 1.5vh;">
                                  {{dato.RESPONSABLE}}</h6>
                              </div>
                            </div>




                          </div>


                          <div class="col-3" style="margin-left: -2vh;" (click)="verDetalles(dato.ID)">



                            <div class="row" (click)="verDetalles(dato.ID)">
                              <div class="col-2">
                                <h6 style="color: #dc0101;text-align: start;" class="d-none d-sm-none d-md-block">
                                  Motivo:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;text-align: justify;"
                                  class="d-none d-sm-block d-md-block">
                                  {{dato.ASUNTO}} </h6>
                              </div>
                            </div>




                          </div>



                          <!--Termina Datos-->

                          <!--Iconos-->



                          <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==2" class="col-2"
                          style="margin-left: 2vh; font-size: 2vh; color: #1b8424;">Solicitud Atendida
                          <img id="icono" src="https://i.imgur.com/xP0CyfZ.png" alt="" height="60" width="60">
                        </div>
                        <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==0"
                          class="col-2"
                          style="margin-left: 2vh; font-size: 2vh; color: #dc0101;">Sin atender
                          <img id="icono" src="https://i.imgur.com/f0aUbIZ.png" alt="" height="60" width="60">
                        </div>
                        <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==1"
                          class="col-2 "
                          style="margin-left: 2vh; font-size: 2vh; color: #a6a104;">En progreso
                          <img id="icono" src="https://i.imgur.com/udHAm0Z.png" alt="" height="60" width="60">
                        </div>


                        </div>
                      </li>
                      <!--Termina Elemento-->


                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                      style="color: #054291; font-weight: bold;">
                      En progreso <span class="badge text-bg-secondary"
                        style="color: rgb(255, 255, 255); background-color: #ede626;margin-left: 2vh; width: 4vh;height: 2vh; text-align: center;">{{this.conteo1}}</span>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <!--Elemento-->

                        <li id="lista1" class="list-group-item animate__animated animate__fadeInDown"
                        (click)="verDetalles(dato.ID)" *ngFor="let dato of data1"
                        style="display:flex; flex-direction:column; justify-content:center">
                        <div class="row">

                          <!--Foto-->
                          <div id="ocultar-div" class="col-2">
                            <img style="border-radius: 50px;" src="https://i.imgur.com/8C8UuNl.png" alt="" height="120"
                              width="120">
                          </div>
                          <!--Termina Foto-->

                          <!--Datos-->

                          <div class="col-1" ></div>

                          <div class="col-4" id="reducir" style="margin-left: -2vh;" (click)="verDetalles(dato.ID)">



                            <div class="row" (click)="verDetalles(dato.ID)">
                              <div class="col-2" >
                                <h6 style="color: #dc0101;text-align: start; font-size: 1.5vh;">
                                  Nombre:
                                </h6>
                              </div>
                              <div class="col-10" style="justify-content: center;text-align: center;">
                                <h6 style="color: #054291;text-align: start; font-size: 1.5vh;">
                                  {{dato.NOMBRE}} {{dato.APELLIDOS}} </h6>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-2"><!--  -->
                                <h6 style="color: #dc0101;text-align: start;"class="d-none d-sm-none d-md-block">
                                  Domicilio:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;"class="d-none d-sm-none d-md-block">
                                  {{dato.DIRECCION}}</h6>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-2">
                                <h6 style="color: #dc0101;text-align: start;" class="d-none d-sm-none d-md-block">
                                  Localidad:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;" class="d-none d-sm-block d-md-block">
                                  {{dato.LOCALIDAD}} </h6>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-2">
                                <h6 style="color: #dc0101; text-align: start;" class="d-none d-sm-none d-md-block">
                                  Fecha:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;" class="d-none d-sm-block d-md-block">
                                  {{dato.FECHA}} </h6>
                              </div>
                            </div>


                            <div class="row">
                              <div class="col-2"><!--  -->
                                <h6 style="color: #dc0101;text-align: start;font-size: 1.5vh;">
                                  Responsable:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;margin-left: 2vh;font-size: 1.5vh;">
                                  {{dato.RESPONSABLE}}</h6>
                              </div>
                            </div>




                          </div>


                          <div class="col-3" style="margin-left: -2vh;" (click)="verDetalles(dato.ID)">



                            <div class="row" (click)="verDetalles(dato.ID)">
                              <div class="col-2">
                                <h6 style="color: #dc0101;text-align: start;" class="d-none d-sm-none d-md-block">
                                  Motivo:
                                </h6>
                              </div>
                              <div class="col-10">
                                <h6 style="color: #054291;text-align: start;text-align: justify;"
                                  class="d-none d-sm-block d-md-block">
                                  {{dato.ASUNTO}} </h6>
                              </div>
                            </div>




                          </div>



                          <!--Termina Datos-->

                          <!--Iconos-->



                          <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==2" class="col-2"
                          style="margin-left: 2vh; font-size: 2vh; color: #1b8424;">Solicitud Atendida
                          <img id="icono" src="https://i.imgur.com/xP0CyfZ.png" alt="" height="60" width="60">
                        </div>
                        <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==0"
                          class="col-2"
                          style="margin-left: 2vh; font-size: 2vh; color: #dc0101;">Sin atender
                          <img id="icono" src="https://i.imgur.com/f0aUbIZ.png" alt="" height="60" width="60">
                        </div>
                        <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==1"
                          class="col-2 "
                          style="margin-left: 2vh; font-size: 2vh; color: #a6a104;">En progreso
                          <img id="icono" src="https://i.imgur.com/udHAm0Z.png" alt="" height="60" width="60">
                        </div>

                        </div>
                      </li>
                      <!--Termina Elemento-->

                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                      style="color: #054291; font-weight: bold;">
                      Completadas <span class="badge text-bg-secondary"
                        style="color: rgb(255, 255, 255); background-color: #09b20c;margin-left: 2vh; width: 4vh;height: 2vh; text-align: center;">{{this.conteo2}}</span>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <!--Elemento-->

                      <li id="lista1" class="list-group-item animate__animated animate__fadeInDown"
                      (click)="verDetalles(dato.ID)" *ngFor="let dato of data2"
                      style="display:flex; flex-direction:column; justify-content:center">
                      <div class="row">

                        <!--Foto-->
                        <div id="ocultar-div" class="col-2">
                          <img style="border-radius: 50px;" src="https://i.imgur.com/8C8UuNl.png" alt="" height="120"
                            width="120">
                        </div>
                        <!--Termina Foto-->

                        <!--Datos-->

                        <div class="col-1" ></div>

                        <div class="col-4" id="reducir" style="margin-left: -2vh;" (click)="verDetalles(dato.ID)">



                          <div class="row" (click)="verDetalles(dato.ID)">
                            <div class="col-2" >
                              <h6 style="color: #dc0101;text-align: start;font-size: 1.5vh;">
                                Nombre:
                              </h6>
                            </div>
                            <div class="col-10" style="justify-content: center;text-align: center;">
                              <h6 style="color: #054291;text-align: start; font-size: 1.5vh;" >
                                {{dato.NOMBRE}} {{dato.APELLIDOS}} </h6>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-2"><!--  -->
                              <h6 style="color: #dc0101;text-align: start;"class="d-none d-sm-none d-md-block">
                                Domicilio:
                              </h6>
                            </div>
                            <div class="col-10">
                              <h6 style="color: #054291;text-align: start;"class="d-none d-sm-none d-md-block">
                                {{dato.DIRECCION}}</h6>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-2">
                              <h6 style="color: #dc0101;text-align: start;" class="d-none d-sm-none d-md-block">
                                Localidad:
                              </h6>
                            </div>
                            <div class="col-10">
                              <h6 style="color: #054291;text-align: start;" class="d-none d-sm-block d-md-block">
                                {{dato.LOCALIDAD}} </h6>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-2">
                              <h6 style="color: #dc0101; text-align: start;" class="d-none d-sm-none d-md-block">
                                Fecha:
                              </h6>
                            </div>
                            <div class="col-10">
                              <h6 style="color: #054291;text-align: start;" class="d-none d-sm-block d-md-block">
                                {{dato.FECHA}} </h6>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-2"><!--  -->
                              <h6 style="color: #dc0101;text-align: start;font-size: 1.5vh;">
                                Responsable:
                              </h6>
                            </div>
                            <div class="col-10">
                              <h6 style="color: #054291;text-align: start;margin-left: 2vh;font-size: 1.5vh;">
                                {{dato.RESPONSABLE}}</h6>
                            </div>
                          </div>





                        </div>


                        <div class="col-3" style="margin-left: -2vh;" (click)="verDetalles(dato.ID)">



                          <div class="row" (click)="verDetalles(dato.ID)">
                            <div class="col-2">
                              <h6 style="color: #dc0101;text-align: start;" class="d-none d-sm-none d-md-block">
                                Motivo:
                              </h6>
                            </div>
                            <div class="col-10">
                              <h6 style="color: #054291;text-align: start;text-align: justify;"
                                class="d-none d-sm-block d-md-block">
                                {{dato.ASUNTO}} </h6>
                            </div>
                          </div>




                        </div>



                        <!--Termina Datos-->

                        <!--Iconos-->



                        <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==2" class="col-2"
                          style="margin-left: 2vh; font-size: 2vh; color: #1b8424;">Solicitud Atendida
                          <img id="icono" src="https://i.imgur.com/xP0CyfZ.png" alt="" height="60" width="60">
                        </div>
                        <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==0"
                          class="col-2"
                          style="margin-left: 2vh; font-size: 2vh; color: #dc0101;">Sin atender
                          <img id="icono" src="https://i.imgur.com/f0aUbIZ.png" alt="" height="60" width="60">
                        </div>
                        <div (click)="verDetalles(dato.ID)" *ngIf="dato.ESTATUS==1"
                          class="col-2 "
                          style="margin-left: 2vh; font-size: 2vh; color: #a6a104;">En progreso
                          <img id="icono" src="https://i.imgur.com/udHAm0Z.png" alt="" height="60" width="60">
                        </div>


                      </div>
                    </li>
                      <!--Termina Elemento-->

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-1">
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next" style="margin-left: 13vh;">
            <span class="carousel-control-next-icon" aria-hidden="true"
              style="background-color: #054291; border-radius: 10vh; width: 4vh;height: 4vh;margin-left: 13vh;"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>




      </div>
    </div>























  </ul>

</div>
<!--Termina Card-->
