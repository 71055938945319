import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';

declare var menu: any;
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent {
  idNoticia: any;
  boton: any;
  noticias: any;
  noticia: any;

  constructor(private activerouter: ActivatedRoute, private router: Router, private api: Fraccion1Service, private general: GeneralService) { }

  ngOnInit(): void {

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.idNoticia = this.activerouter.snapshot.paramMap.get('id');
    console.log(this.idNoticia);

    this.general.verPersonal().subscribe(res => {
      this.noticias = res.body;
      console.log(this.noticias);

    })
    menu()


  }

  subirNoticia() {
    localStorage.setItem('idNoticia', this.idNoticia);
    this.router.navigate(['admin/subir-noticia']);
  }
}
