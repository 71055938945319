<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>

  <section></section>
  <section id="blog" class="blog">
    <div class="container" data-aos="fade-up">

      <div class="row">


        <div style="justify-content: center; text-align: center;" *ngIf="this.boton===1">
          <button style="border: 0px; background-color: rgba(255, 255, 255, 0);"> <a href="admin/gobierno/subir"
              style="color: aliceblue;"><img src="https://img.icons8.com/material-outlined/24/null/edit-image.png" />
            </a> </button>
        </div>


        <div *ngFor="let dato of noticias" class="col-lg-8 entries" style="width: 100%;margin: auto; margin-top: 2vh;">

          <article class="entry entry-single">

            <h2 style="text-align: center;" class="entry-title">{{dato.ENCABEZADO}}</h2>

            <div class="entry-content">
              <p style="text-align: justify;" [innerHTML]="dato.CUERPO">
              </p>
            </div>

          </article>

        </div><!-- End blog entries list -->

        <div class="astrodivider" data-aos="fade-up">
          <div class="astrodividermask" data-aos="fade-up"></div><span
            style=" background-image: url(https://i.imgur.com/WVvdtYK.png); justify-items: start;"></span>
        </div>
        <div data-aos="fade-up" class="col-lg-4"
          style="width: 100%;margin: auto; justify-content: center;text-align: center;">

          <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR5965G1IPR4967G1ORGANIGRAMA%20AYUNTAMIENTO%202021%20-%202024.pdf?alt=media&token=49ec28b3-3f46-4860-9882-29530e0bda3b"
            style=" font-size: 3vh; font-weight: 600; text-align: center;">Organigrama 2021 - 2024</a>
        </div>

        <br>

        <div data-aos="fade-up" *ngFor="let dato of noticias"
          style="box-shadow: -5px 6px 20px 0px rgba(190, 190, 190, 0.753); margin-top: 2vh;" class="col-lg-12">
          <br>
          <img [src]="this.dato.ENLACE" style="width: 100%;margin: auto;">

        </div><!-- End blog sidebar -->

      </div>

    </div>
  </section><!-- End Blog Single Section -->



  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
