<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">


    <h2
      style="text-align: center; width: 100%;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; margin-top: 3vh; margin-bottom: 2vh; color: brown;">
      PROTECCION CIVIL</h2>
    <div class="container" data-aos="fade-up" >
      <div class="row" >

<div class="col-4">

</div>

        <div class="col-lg-4" data-aos-delay="200" >
          <div style="border-radius: 3vh;" class="icon-box iconbox-orange " style="height: 500px;">
            <a  href="admin/proteccion-civil/simulacro">
              <div class="icon">
                <img src="https://i.imgur.com/c5eY121.png"  height="130" width="1300" alt="">
                <img src="https://i.imgur.com/oGuTsJG.png" height="70" width="70" style="margin-left: -15%;" alt="">
              </div>
 </a>

              <h4 style=" margin-top: 2vh;">Primer Simulacro Nacional 2023</h4>
              <button style="background-color: brown;color: aliceblue; border-radius: 1vh; border: solid; border-color: brown;" (click)="navegar()"> Registrarse</button> <br>
              <button style="background-color: brown;color: aliceblue; border-radius: 1vh; border: solid; border-color: brown;margin-top: 1vh;" (click)="constancia()"> Constancia</button>


            <div class="col-12" style="background-color: brown; width: 109%; height: 75%; margin-top: 3vh; margin-left: -2vh;border-bottom-left-radius: 3vh;border-bottom-right-radius: 3vh; box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); height: 200px;"  >
              <h3 style="color: rgb(255, 255, 255); margin-top: 5vh;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; width: 100%; text-align: center;">Primer Simulacro  Nacional<br> 2023</h3>
            </div>


           </div>
          </div>

<div class="col-4">

</div>



      </div>
    </div>
  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
