<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->

  <section id="blog" class="blog" style="margin-top: 50px;">
    <div class="section-title" data-aos="fade-up">
      <h4 style="color: rgb(255, 255, 255);"></h4>

      <div style="justify-content:center; text-align:center;">
        <form [formGroup]="documentoForm">

          <div style="border-color: #ec1800;border: solid;border-radius: 2vh;background-color: #ffffff;">

            <img src="https://i.imgur.com/QsssjDm.png" width="900px" height="90px" style="border-radius: 1vh;">

            <br><label style="margin-right: 1%;font-weight: bold; color: rgb(255, 255, 255);">Texto No.1:</label>
            <br><input type="text" style="margin-top: 1%; width: 60%;margin-bottom: 2vh;" formControlName="DATO1">

          </div>
          <br><br>

          <div style="border-color: #ec1800;border: solid;border-radius: 2vh;background-color: #ffffff;">

            <img src="https://i.imgur.com/IfGALRA.png" alt="" width="900px" height="90px" style="border-radius: 1vh;">

            <br><label style="margin-right: 1%;font-weight: bold;color: rgb(255, 255, 255);">Texto No.2:</label>
            <br><input type="text" style="margin-top: 1%; width: 60%;margin-bottom: 2vh;" formControlName="DATO2">

          </div>
          <br><br>

          <div style="border-color: #ec1800;border: solid;border-radius: 2vh;background-color: #ffffff;">
            <img src="https://i.imgur.com/DFdilzk.png" width="900px" height="90px" style="border-radius: 1vh;">

            <br><label style="margin-right: 1%;font-weight: bold; color: rgb(255, 255, 255);">Enlace de Twitter:</label>
            <br><input type="text" style="margin-top: 1%; width: 60%;margin-bottom: 2vh;" formControlName="TWITTER">
          </div>
          <br><br>

          <div style="border-color: #ec1800;border: solid;border-radius: 2vh;background-color: #ffffff;">
            <img src="https://i.imgur.com/NH75yZY.png" alt="" width="900px" height="90px" style="border-radius: 1vh;">

            <br><label style="margin-right: 1%;font-weight: bold;color: rgb(255, 255, 255);">Enlace de Facebook:</label>
            <br><input type="text" style="margin-top: 1%; width: 60%; margin-bottom: 2vh;" formControlName="FACEBOOK">

          </div>

          <br><br>

          <div style="border-color: #ec1800;border: solid;border-radius: 2vh;background-color: #ffffff;">
            <img src="https://i.imgur.com/6fa6F25.png" alt="" width="900px" height="90px" style="border-radius: 1vh;">

            <br><label style="margin-right: 1%;font-weight: bold;color: rgb(255, 255, 255);">Enlace de Youtube:</label>
            <br><input type="text" style="margin-top: 1%; width: 60%;margin-bottom: 2vh;" formControlName="YOUTUBE">

          </div>

          <br><br>

          <div style="border-color: #ec1800;border: solid;border-radius: 2vh;background-color: #ffffff;">
            <img src=" https://i.imgur.com/YYpw9ae.png" alt="" width="900px" height="90px" style="border-radius: 1vh;">

            <br><label style="margin-top: 2%; margin-right: 1%;font-weight: bold;color: rgb(255, 255, 255);">Elija la
              nueva imagen para el logo:</label>
            <br><input type="file" style="margin-top: 1%;margin-bottom: 2vh" (change)="changeFileMenu($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>

          </div>

          <br><button style="margin-top: 2%;font-weight: bold;color: rgb(255, 255, 255);"
            (click)="cargar()">Aceptar</button>
        </form>
      </div>

    </div>

  </section>


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
