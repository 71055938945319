import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-secretaria-general',
  templateUrl: './secretaria-general.component.html',
  styleUrls: ['./secretaria-general.component.scss']
})
export class SecretariaGeneralComponent {
  menu: any;
  giros: any;
  provision: any;
  constancia: any;

  boton: any;
  id: any;
  displayStyleEditar = "none";

  //Variables para url de archivos
  urlDocumento = '';

  //Variables para indicar carga de archivos
  subiendoDocumento = false;

  editarForm = new FormGroup({
    ID: new FormControl(''),
    ICONO: new FormControl(''),
    TITULO: new FormControl(''),
    AREA: new FormControl(''),
    SUBAREA: new FormControl('')
  })

  archivos: string[] = [];
  areas: any;
  areas1: any;
  menus: any;
  titulo: any;
  icono: any;
  area: any;
  subarea: any;

  constructor(private api: GeneralService, private alerts: SweetAlertService, private storage: Storage, private router: Router) { }

  ngOnInit(): void {

    this.menu = 0;
    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.api.verAreas1(3, 0).subscribe(res => {
      this.areas = res.body;
      console.log(this.areas);

    })

    this.api.verAreas1(3, 1).subscribe(res => {
      this.areas1 = res.body;
      console.log(this.areas1);

    })

    this.api.verAreas().subscribe(res => {
      this.menus = res.body;
      console.log(this.menus);

    })
  }

  clickMenu() {
    localStorage.setItem('menu', '1')
  }

  clickGiro() {
    localStorage.setItem('menu', '1')
    localStorage.setItem('giro', '1')
  }

  clickProvision() {
    localStorage.setItem('menu', '1')
    localStorage.setItem('provision', '1')
  }

  clickConstancia() {
    localStorage.setItem('menu', '1')
    localStorage.setItem('constancia', '1')
  }

  openModalEditar(ID: any, AREA: any, SUBAREA: any, INDICE: any) {
    this.id = ID;
    this.area = AREA;
    this.subarea = SUBAREA;
    console.log(ID);

    this.displayStyleEditar = "block";
    this.api.verAreas1(AREA, SUBAREA).subscribe(res => {
      this.areas1 = res.body;
      this.titulo = this.areas1[INDICE].TITULO;
      this.icono = this.areas1[INDICE].ICONO;
      console.log(this.areas1);
      console.log(this.titulo);

    })
  }
  closePopUpEditar() {
    this.displayStyleEditar = "none";
    this.editarForm.reset();
  }

  editar() {
    this.editarForm.value.AREA = this.area;
    this.editarForm.value.SUBAREA = this.subarea;
    this.editarForm.value.ID = this.id;
    console.log(this.editarForm.value);

    this.api.actualizarAreas(this.editarForm.value).subscribe(res => {
      this.alerts.alertaRealizadoAsistencia('Completado', 'Se ha editado con exito');
      this.closePopUpEditar();
      location.reload();
    })

  }

  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })

  async changeFileMenu(event: any) {

    let file = event.target.files[0];
    this.archivos[0] = file.name;

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Tramites';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + this.archivos[0]); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);

          this.editarForm.value.ICONO = file;
          console.log(this.editarForm.value.ICONO);

        }).catch(error => { console.log(error) });

      })
    })

  }
}
