<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">
    <nav>
      <div class="container2">
        <input id="responsive-menu" type="checkbox">
        <label for="responsive-menu">Menú <span id="menu-icon"></span></label>
        <div id="overlay" style="background-color: rgb(255, 255, 255);"></div>
        <ul>
          <li *ngFor="let dato of menu"><a href="admin/tramites-y-servicios/{{dato.AREA}}">{{dato.TITULO}}</a></li>
        </ul>
      </div>
    </nav>

    <h3
      style="text-align: center; width: 100%;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; margin-top: 3vh; margin-bottom: 2vh;">
      {{areas1[2].TITULO}}</h3>
    <div class="container" data-aos="fade-up">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del documento</th>
            <th scope="col"></th>

          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let dato of areas, let i = index">
            <td><img src="assets/img/docs.png" alt="" height="30" width="30">
              <a [href]="dato?.DOCUMENTO" target="_blank" style="text-align: end;">{{dato?.TITULO}} </a>
            </td>
            <td>
              <button *ngIf="boton === 1" style="border-radius: 1vh; width: 130px; margin-top: 15px;"
                (click)="openModalEditar(dato.ID, dato.AREA, dato.SUBAREA , i)">Editar<i
                  class="bi bi-pencil-square"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section><!-- End Services Section -->

  <!-- Inicio Editar -->
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleEditar}" style="margin-top: 25vh;">
    <form [formGroup]="editarForm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div style="display: flex;flex-direction: column;">
              <h2 class="modal-title">Editar</h2>
            </div>
          </div>
          <div class="modal-body">
            <div style="display: flex;justify-content: space-between; margin-top: 2%;">
              <div class="row">
                <label style="font-weight: bold;">
                  Titulo actual:
                </label>
                <input type="text" style="width: 100%; margin-left: 13px; height: 25px;" readonly
                  placeholder="{{this.titulo}}">
              </div>
            </div>
            <div style="display: flex;justify-content: space-between; margin-top: 2%;">
              <div class="row">
                <label style="font-weight: bold;">
                  Escriba el nuevo titulo:
                </label>
                <input type="text" style="width: 98%; margin-left: 13px; height: 25px;" formControlName="TITULO">
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div>
                <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Elija el documento a
                  subir</label>
                <input #File type="file" style="margin-top: 1%;" (change)="changeFileMenu($event)">
                <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
                  *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

                <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="closePopUpEditar()">
              Cancelar
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="cargar()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- Final Editar -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
