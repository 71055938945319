import { Component } from '@angular/core';

@Component({
  selector: 'app-catedral',
  templateUrl: './catedral.component.html',
  styleUrls: ['./catedral.component.scss']
})
export class CatedralComponent {

}
