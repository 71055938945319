import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-proteccion-civil',
  templateUrl: './proteccion-civil.component.html',
  styleUrls: ['./proteccion-civil.component.scss']
})
export class ProteccionCivilComponent {
  boton: any;
  id: any;

  areas: any;
  area: any;
  subarea: any;
  areas1: any;
  menu: any;
  titulo: any;
  icono: any;
  archivos: string[] = [];

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router) { }

  ngOnInit(): void {

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.api.verAreas().subscribe(res => {
      this.menu = res.body;
      console.log(this.menu);

    })
  }


navegar(){
  this.router.navigate(['admin/proteccion-civil/simulacro' ])
}

constancia(){
  this.router.navigate(['admin/proteccion-civil/filtro' ])
}

}
