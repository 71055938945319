<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>


<body>
  <section>
    <nav>
      <div class="container2" style="margin-top: 15vh;">
        <input id="responsive-menu" type="checkbox">
        <label for="responsive-menu">Menú <span id="menu-icon"></span></label>
        <div id="overlay" style="background-color: rgb(250, 250, 250);"></div>
        <ul>
          <li *ngFor="let dato of menu"><a href="admin/tramites-y-servicios/{{dato.AREA}}">{{dato.TITULO}}</a></li>
        </ul>
      </div>
    </nav>


    <div class="row" style="justify-content: center; text-align: center;">

      <div class="col-12" style=" box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753); width: 60%;border-radius: 2vh; ">
        <img src="https://i.imgur.com/4f3jDMa.png" alt="">
      </div>
      <br>
      <div class="col-12" style=" box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753); width: 60%;border-radius: 3vh; margin-top: 2vh;">
        <img src="https://i.imgur.com/4YhwBKX.png" alt="">
      </div>
      <div class="col-12" style=" box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753); width: 60%;border-radius: 3vh; margin-top: 2vh;">
        <img src="https://i.imgur.com/4YhwBKX.png" alt="">
      </div>
      <div class="col-12" style=" box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753); width: 60%;border-radius: 3vh; margin-top: 2vh;">
        <img src="https://i.imgur.com/EfhMU00.png" alt="">
      </div>
      <div class="col-12" style=" box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753); width: 60%;border-radius: 3vh; margin-top: 2vh;">
        <img src="https://i.imgur.com/EfhMU00.png" alt="">
      </div>
      <div class="col-12" style=" box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753); width: 60%;border-radius: 3vh; margin-top: 2vh;">
        <img src="https://i.imgur.com/e1UQ4aB.png" alt="">
      </div>
      <div class="col-12" style=" box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753); width: 60%;border-radius: 3vh; margin-top: 2vh;">
        <img src="https://i.imgur.com/qCzOaBP.png" alt="">
      </div>


    </div>



  </section>
</body>
